<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="PurchasingReport"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
        <purchasing-order-report-pdf ref = "purchasingOrderReportPDF"/>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import purchasingOrderReportServices from '../Script/PurchasingOrderReportService.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid, sweet_alert, datetime_zone } from '../../../../infrastructure/constant/variable';
import moment from 'moment';
import { response } from '../../../../infrastructure/constant/response';
import purchasingOrderReportPdf from '../../../../assets/template/PurchasingOrderReportPdfTemplate.vue'

export default {
    name: 'PurchasingOrderReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'purchasing-order-report-pdf': purchasingOrderReportPdf
    },
    props: ['dateTo', 'dateFrom', 'item', 'supplier', 'onClickPDF'],
    computed: {
        PurchasingReport () {
            var dateTo = this.$props.dateTo;
            var dateFrom = this.$props.dateFrom;
            var groupData = this.$props.group;
            var itemData = this.$props.item;
            var supplierData = this.$props.supplier;

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                scrollable: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                                itemId: (itemData != null ? itemData.value : itemData),
                                supplierId: (supplierData != null ? supplierData.value : supplierData),
                            };
                            return { 
                                query: purchasingOrderReportServices.readPurchasingOrderReport(),
                                variables:variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetPurchasingPurchaseOrderReport.detail == null){
                            return [];
                        }else{
                            return response.data.GetPurchasingPurchaseOrderReport.detail;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetPurchasingPurchaseOrderReport.detail == null){
                            return 0;
                        }else{
                            return response.data.GetPurchasingPurchaseOrderReport.detail.length;
                        }
                    },
                    model: {
                        fields: {
                            ppn_tax_percentage: {type: "number" },
                            exchange_rate: {type: "number" },
                            request_quantity: {type: "number" },
                            ordered_quantity: {type: "number" },
                            remaining_quantity: {type: "number" },
                            quantity: {type: "number" },

                            purchase_order_date: {type: "date"},
                        }
                    }
                },
                group: groupData,              
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "purchase_order_number", title: "No. PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "purchase_order_date", title: "Tanggal PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(purchase_order_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "request_number", title: "No. Request", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "supplier_name", title: "Vendor", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },          
                { field: "ppn_tax_percentage", title: "PPN %", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "currency_name", title: "Mata Uang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "exchange_rate", title: "Exchange Rate", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: 'product_code', title: "Kode Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;"} },
                { field: 'item_name', title: "Nama Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'uom', title: "Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}},
                { field: 'request_quantity', title: "Request Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}},
                { field: 'ordered_quantity', title: "Ordered Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}},
                { field: 'remaining_quantity', title: "Remaining Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}},
                { field: 'quantity', title: "Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}},
                { field: 'delivered_quantity', title: "Received Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}},
                { field: 'vat_per_unit', title: "Harga Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}"},
                { field: 'vat', title: "Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}"},
            ],
           
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
           
        };
    },
    mounted: async function() {
       
    },
    methods: {
        async exportExcel() {
            const variables = {
                startDate: this.DateFrom,
                endDate: this.DateTo
            };

            // var dataItem = await purchasingOrderReportServices.getPurchasingOrderReport(variables);
            var gridData = this.$refs.grid.kendoWidget()._data;
            var data = await purchasingOrderReportServices.purchasingOrderReportExcel(gridData, variables);
            if (gridData == null){
                this.$swal("Error", response.noRecord, "error")
            }
            else {
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_PurchasingOrder_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = purchasingOrderReportServices.purchasingOrderReportExcelHeaderSize();
                
                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename); 
            }
        },
        generatePDF(){
            // var gridData = this.$refs.grid.kendoWidget()._data;

            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.purchasingOrderReportPDF.generatePDF(gridData);
            //     }
            //     else if (result.isDenied) {                    
            var startDate = this.dateFrom;
            var endDate = this.dateTo;

            const routeData = this.$router.resolve({name: 'Purchasing Order Report Html Template', params: { startdate:startDate, enddate:endDate }});
            window.open(routeData.href, '_blank');
            //     }
            // });
        }
    },
    
}
</script>