<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="PurchasingReport"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
        <purchasing-delivery-order-report-pdf ref = "purchasingDeliveryOrderReportPDF"/>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import moment from 'moment';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import purchasingDeliveryOrderReportServices from '../Script/PurchasingDeliveryOrderReportService.js';
import { kendo_grid, sweet_alert, datetime_zone } from '../../../../infrastructure/constant/variable';
import { response } from '../../../../infrastructure/constant/response';
import purchasingDeliveryOrderReportPdf from '../../../../assets/template/PurchasingDeliveryOrderReportPdfTemplate.vue'


export default {
    name: 'PurchasingDeliveryOrderReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'purchasing-delivery-order-report-pdf': purchasingDeliveryOrderReportPdf
    },
    props: ['dateTo', 'dateFrom', 'group'],
    computed: {
        PurchasingReport () {
            var dateTo = this.$props.dateTo;
            var dateFrom = this.$props.dateFrom;
            var groupData = this.$props.group;

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to
                            };
                            return { 
                                query: purchasingDeliveryOrderReportServices.readPurchasingDeliveryOrderReport(),
                                variables:variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetPurchasingDeliveryOrderReport.detail == null){
                            return [];
                        }else{
                            return response.data.GetPurchasingDeliveryOrderReport.detail;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetPurchasingDeliveryOrderReport.detail == null){
                            return 0;
                        }else{
                            return response.data.GetPurchasingDeliveryOrderReport.detail.length;
                        }
                    },
                    model: {
                        fields: {
                            invoice_quantity: {type: "number" },
                            delivered_quantity: {type: "number" },
                            remaining_quantity: {type: "number" },
                            quantity: {type: "number" },

                            created_at : {type: "date"},
                            delivery_order_date: {type: "date"},
                        }
                    }
                },
                group: groupData              
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "delivery_order_number", title: "No. Delivery", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "delivery_order_date", title: "Tanggal Delivery", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(delivery_order_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "invoice_number", title: "No. Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "supplier_name", title: "Vendor", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'product_code', title: "Kode Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;"} },
                { field: 'item_name', title: "Nama Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: 'uom', title: "Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                // { field: 'invoice_quantity', title: "Invoice Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: 'delivered_quantity', title: "Delivery Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: 'remaining_quantity', title: "Remaining Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: 'quantity', title: "Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            }, 
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
       
    },
    methods: {
      async exportExcel() {
            const variables = {
                startDate: this.$props.dateFrom,
                endDate: this.$props.dateTo
            };
            var gridData  = this.$refs.grid.kendoWidget()._data;
            // var dataItem = await purchasingDeliveryOrderReportServices.getPurchasingDeliveryOrderReport(variables);
            var data = await purchasingDeliveryOrderReportServices.purchasingDeliveryOrderReportExcel(gridData, variables);
            if(gridData.length == 0) {
                this.$swal("Error", response.noRecord, "error");
            }
            else {
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_PurchasingDeliveryOrder_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = purchasingDeliveryOrderReportServices.purchasingDeliveryOrderReportExcelHeaderSize();
                
                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename); 
            }
        },
        generatePDF(){
            // var gridData  = this.$refs.grid.kendoWidget()._data;

            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.purchasingDeliveryOrderReportPDF.generatePDF(gridData);
            //     }
            //     else if (result.isDenied) {                    
            var startDate = this.dateFrom;
            var endDate = this.dateTo;

            const routeData = this.$router.resolve({name: 'Purchasing Delivery Order Report Html Template', params: { startdate:startDate, enddate:endDate }});
            window.open(routeData.href, '_blank');
            //     }
            // });
        }
    },
}
</script>