<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Purchasing Invoice Payment</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">&nbsp;</label>
                            </CCol>
                        </CRow>
                        <CRow class="pb-0">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow class="pb-3">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateFrom"
                                >
                                </date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%;"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">Quantity Per Status</label>
                            </CCol>
                        </CRow>
                        <div style="border:1px solid lightgrey;padding:10px">
                        <CRow>
                            <CCol class="sm-1">
                                <CRow>
                                    <CCol class="sm-4">
                                        <label class="form-label font-weight">New</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-3">
                                        <label class="form-label font-weight">{{NewQty}}</label>
                                    </CCol>
                                </CRow>
                                 <CRow>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">Unclear</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{UnclearQty}}</label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                <CCol class="sm-1">
                                        <label class="form-label font-weight">Clear</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{ClearQty}}</label>
                                </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">Delete</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{DeleteQty}}</label>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                        </div>
                    </CCol>
                    <!-- Total Vat Per Status -->
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">Total Payment Per Status</label>
                            </CCol>
                        </CRow>
                        <div style="border:1px solid lightgrey;padding:10px">
                        <CRow>
                            <CCol>
                                <CRow>
                                    <CCol class="sm-4">
                                        <label class="form-label font-weight">New</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-3">
                                        <label class="form-label font-weight">{{NewTotal}}</label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="sm-1">
                                            <label class="form-label font-weight">Unclear</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{UnclearTotal}}</label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">Clear</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{ClearTotal}}</label>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">Delete</label>
                                        <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                    </CCol>
                                    <CCol class="sm-1">
                                        <label class="form-label font-weight">{{DeleteTotal}}</label>
                                    </CCol>
                                </CRow>
                            </CCol>
                        </CRow>
                        </div>
                    </CCol>
                </CRow>
                
                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i>Excel
                        </button>
                        &nbsp;
                        <button id="view" class="btn btn-secondary"
                            @click="generatePDF()">
                            <i class="fa fa-file-pdf-o"></i>PDF
                        </button>
                    </CCol>
                </CRow>
                <hr>

                <CRow>
                    <CCol>
                        <purchasing-invoice-payment-report-grid ref="purchasingInvoicePaymentReportGrid"
                            :key="gridReload" 
                            :dateFrom="DateFromChangest" 
                            :dateTo="DateToChangest" 
                            />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import purchasingInvoicePaymentReportGrid from '../PurchasingInvoicePaymentReport/Grid/PurchasingInvoicePaymentReportGrid.vue'
import purchasingInvoicePaymentReportService from '../PurchasingInvoicePaymentReport/Script/PurchasingInvoicePaymentReportService.js'
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'
import { report, datetime_zone } from '../../../infrastructure/constant/variable';


export default {
    name: 'PurchasingInvoiceReport',
    components: {
        'purchasing-invoice-payment-report-grid': purchasingInvoicePaymentReportGrid,
        'date-picker': datePicker,
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();
        
        this.onChangeDateType();

        var variables = {
            startDate: this.DateFrom + datetime_zone.date_from,
            endDate: this.DateTo + datetime_zone.date_to,
        };
        this.getQuantity(variables);
    },
   
    data(){
        return{
            DateTypeData: [],
            DateType: report.default_date,

            DateFrom: '',
            DateTo: '',

            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,

            gridReload: 0,
            
            NewQty: '',
            ClearQty:'',
            UnclearQty:'',
            DeleteQty:'',

            NewTotal:'',
            ClearTotal:'',
            UnclearTotal:'',
            DeleteTotal:'',
            Error:0,
            
            GroupingBy: '',
            group: [],
        }
    },
    methods: {
        async getQuantity(variables){
            var dataPurchasingInvoicePaymentReportSummary = await purchasingInvoicePaymentReportService.getPurchasingInvoicePaymentReport(variables);
             var summaryQuantity = dataPurchasingInvoicePaymentReportSummary.summary.quantity_per_status;
            var summaryTotal = dataPurchasingInvoicePaymentReportSummary.summary.total_payment_per_status;

            this.NewQty = summaryQuantity.new;
            this.ClearQty = summaryQuantity.clear;
            this.UnclearQty = summaryQuantity.unclear;
            this.DeleteQty = summaryQuantity.delete;

            this.NewTotal = kendo.format('{0:N0}', summaryTotal.new);
            this.ClearTotal= kendo.format('{0:N0}', summaryTotal.clear);
            this.UnclearTotal = kendo.format('{0:N0}', summaryTotal.unclear);
            this.FinishTotal = kendo.format('{0:N0}', summaryTotal.finish);
            this.DeleteTotal = kendo.format('{0:N0}', summaryTotal.delete);
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);
            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            
            var variables = {
                startDate: this.DateFrom + datetime_zone.date_from,
                endDate: this.DateTo + datetime_zone.date_to,
            };
            await this.getQuantity(variables);

            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                
                var variables = {
                    startDate: this.DateFrom + datetime_zone.date_from,
                    endDate: this.DateTo + datetime_zone.date_to,
                };
                await this.getQuantity(variables);

                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                
                var variables = {
                    startDate: this.DateFrom + datetime_zone.date_from,
                    endDate: this.DateTo + datetime_zone.date_to,
                };
                await this.getQuantity(variables);

                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async exportExcel() {
            this.$refs.purchasingInvoicePaymentReportGrid.exportExcel();
        },
        generatePDF(){
            this.$refs.purchasingInvoicePaymentReportGrid.generatePDF();
        }
    }
}
</script>

<style scoped>
</style>