<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Purchasing Invoice</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">&nbsp;</label>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateFrom"
                                ></date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pt-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>                        

                        <CRow class="pt-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Status Payment</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <v-select id="StatusPayment" class="pb-3" :options="StatusPaymentData" v-model="StatusPayment" @input="onChangeStatusPayment()"/>
                                <label id="errorStatusPayment" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol> 
                        </CRow>

                        <CRow >
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Status Invoice</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <v-select id="Status" class="pb-3" :options="StatusData" v-model="Status" @input="onChangeStatus()"/>
                                <label id="errorStatus" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol> 
                        </CRow>
                    </CCol>
                    
                    <!-- Quantity Per Status-->
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">Quantity Per Status</label>
                            </CCol>
                        </CRow>
                        <div style="border:1px solid lightgrey;padding:10px">
                            <CRow>
                                <CCol class="sm-1">
                                    <CRow>
                                        <CCol class="sm-4">
                                            <label class="form-label font-weight">New</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-3">
                                            <label class="form-label font-weight">{{NewQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Posting</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{PostingQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                    <CCol class="sm-1">
                                            <label class="form-label font-weight">Payment</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{PaymentQty}}</label>
                                    </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Finish</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{FinishQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Delete</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{DeleteQty}}</label>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                    
                    <!-- Total Vat Per Status -->
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">Total Price Per Status</label>
                            </CCol>
                        </CRow>
                        <div style="border:1px solid lightgrey;padding:10px">
                            <CRow>
                                <CCol>
                                    <CRow>
                                        <CCol class="sm-4">
                                            <label class="form-label font-weight">New</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-3">
                                            <label class="form-label font-weight">{{NewTotal}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Posting</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{PostingTotal}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                    <CCol class="sm-1">
                                            <label class="form-label font-weight">Payment</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{PaymentTotal}}</label>
                                    </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Finish</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{FinishTotal}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Delete</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{DeleteTotal}}</label>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                </CRow>
                
                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                        &nbsp;
                        <button id="view" class="btn btn-secondary"
                            @click="generatePDF()">
                            <i class="fa fa-file-pdf-o"></i> PDF
                        </button>
                    </CCol>
                </CRow>

                <hr>
                <CRow>
                    <CCol>
                        <purchasing-invoice-report-grid 
                            ref="purchasingInvoiceReportGrid"
                            :key="gridReload" 
                            :dateFrom="DateFromChangest" 
                            :dateTo="DateToChangest" 
                            :statusPayment="StatusPayment"
                            :status="Status"
                        />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'
import { report, datetime_zone } from '../../../infrastructure/constant/variable';
import purchasingInvoiceReportGrid from '../PurchasingInvoiceReport/Grid/PurchasingInvoiceReportGrid.vue';
import purchasingInvoiceReportService from '../PurchasingInvoiceReport/Script/PurchasingInvoiceReportService.js';

export default {
    name: 'PurchasingInvoiceReport',
    components: {
        'purchasing-invoice-report-grid': purchasingInvoiceReportGrid,
        'date-picker': datePicker
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();

        this.onChangeDateType();

        var variables = {
            startDate: this.DateFrom + datetime_zone.date_from,
            endDate: this.DateTo + datetime_zone.date_to,
        };
        this.getQuantity(variables)
        
        this.StatusPayment = null;
        this.Status = null;
    },
   
    data(){
        return{
            DateTypeData: [],
            DateType: report.default_date,

            DateFrom: '',
            DateTo: '',

            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,

            gridReload: 0,

            NewQty: '',
            PostingQty:'',
            PaymentQty:'',
            FinishQty:'',
            DeleteQty:'',

            NewTotal:'',
            PostingTotal:'',
            PaymentTotal:'',
            FinishTotal:'',
            DeleteTotal:'',
            Error:0,
            
            StatusPaymentData: [
                {value: 'PAID', label: 'Paid'},
                {value: 'UNPAID', label: 'Unpaid'},
            ],
            StatusPayment: '',

            StatusData: [
                {value: 'New', label: 'New'},
                {value: 'Delete', label: 'Delete'},
                {value: 'Finish', label: 'Finish'},
                {value: 'Payment', label: 'Payment'},
                {value: 'Posting', label: 'Posting'},
            ],
            Status: '',
        }
    },
    methods: {
        async getQuantity(variables){
            var dataPurchasingInvoiceReportSummary = await purchasingInvoiceReportService.getPurchasingInvoiceReport(variables);
            var summaryQuantity = dataPurchasingInvoiceReportSummary.summary.quantity_per_status;
            var summaryTotal = dataPurchasingInvoiceReportSummary.summary.total_vat_per_status;

            this.NewQty = summaryQuantity.new;
            this.PostingQty = summaryQuantity.posting;
            this.PaymentQty = summaryQuantity.payment;
            this.FinishQty = summaryQuantity.finish;
            this.DeleteQty = summaryQuantity.delete;

            this.NewTotal = kendo.format('{0:N0}', summaryTotal.new);
            this.PostingTotal = kendo.format('{0:N0}', summaryTotal.posting);
            this.PaymentTotal = kendo.format('{0:N0}', summaryTotal.payment);
            this.FinishTotal = kendo.format('{0:N0}', summaryTotal.finish);
            this.DeleteTotal = kendo.format('{0:N0}', summaryTotal.delete);
        },
        async onChangeStatus(){
            if(this.Status == '') {
                this.Status = null;
            }
            this.gridReload++;
        },
        async onChangeStatusPayment(){
            if(this.StatusPayment == '') {
                this.StatusPayment = null;
            }
            this.gridReload++;
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);
            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            
            var variables = {
                startDate: this.DateFrom + datetime_zone.date_from,
                endDate: this.DateTo + datetime_zone.date_to,
            };
            await this.getQuantity(variables);

            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                
                var variables = {
                    startDate: this.DateFrom + datetime_zone.date_from,
                    endDate: this.DateTo + datetime_zone.date_to,
                };
                await this.getQuantity(variables);

                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                
                var variables = {
                    startDate: this.DateFrom + datetime_zone.date_from,
                    endDate: this.DateTo + datetime_zone.date_to,
                };
                await this.getQuantity(variables);

                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async exportExcel() {
            this.$refs.purchasingInvoiceReportGrid.exportExcel();
        },
        generatePDF(){
            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.purchasingInvoiceReportGrid.generatePDF();
            //     }
            //     else if (result.isDenied) {
            const routeData = this.$router.resolve({name: 'Purchasing Invoice Report Html Template', params: { startdate: this.DateFrom, enddate: this.DateTo }});
            window.open(routeData.href, '_blank');
            //     }
            // });
        }
    }
}
</script>

<style scoped>
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>